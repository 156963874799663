<!--
  PACKAGE_NAME : src/pages/cc/ivr/schedule/config/holiday.vue
  FILE_NAME : holiday
  AUTHOR : hmlee
  DATE : 2024-10-8
  DESCRIPTION : 운영스케줄관리 설정 - 공휴일설정 컴포넌트
-->
<template>
	<div>
    <div class="head-btn-box01">
      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @init-new-row="onInitNewRow" @saving="onSaving"/>
    </div>

    <!-- 휴일 불러오기 팝업 -->
    <ModalHolidaytp
      v-if="modal.holidaytp.visible"
      :is-open="modal.holidaytp.visible"
      :holidaytp-list="modal.holidaytp.data"
      @saveModal="onSaveModal('holidaytp', $event)"
      @closeModal="onCloseModal('holidaytp')"
    />
	</div>
</template>

<script>
import ModalHolidaytp from '@/pages/cc/ivr/schedule/config/modal-holidaytp.vue';
import { getPastFromToday } from '@/plugins/common-lib';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";
import { DxSwitch } from 'devextreme-vue/switch';
import moment from "moment/moment";

export default {
	components: {
    EspDxDataGrid,
    DxSwitch,
		ModalHolidaytp,
	},
	props: {
    /** @description: 선택된 요일 */
    checkedDays: {
      type: Array,
    },
    /** @description: 공휴일 설정 데이터 */
    holidayList: {
      type: Array,
    }
  },
	watch: {
    /** @description: 선택된 요일 */
    checkedDays: {
      handler: 'setWorkFl',
    },
    /** @description: 공휴일 설정 데이터 */
    holidayList(value) {
      this.$refs[this.dataGrid.refName].getGridInstance.option('dataSource', value);
    }
  },
  data() {
    return {
      modal: {
        holidaytp: {
          visible: false,
          data: {},
        },
      },
      isCheckedHoliday: false, // 공휴일 운영 여부
      dataGrid: {
        callApi: 'CALL_CC_API',
        refName: 'holidayGrid',
        keyExpr: 'id',
        allowColumnResizing: true, //컬럼 사이즈 허용
        allowReordering: true, //inline속성 느낌
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        hoverStateEnabled: true,
        dataSourceDefaultSortColumn: '+holidayDt',
        dataSource: [],
        // width:'200',     // 주석처리시 100%
        height: '350', // 주석처리시 100%
        apiActionNm: {},
        customEvent: {
          initNewRow: true, // 신규 행 추가 이벤트
          saving: true, // 신규 행 추가 이벤트
        },
        hideSaveBtn: true, //저장 버튼 숨김 여부
        hideCancelBtn: false, //취소 버튼 숨김 여부
        showActionButtons: {
          // 그리드 버튼 노출 설정값
          select: false,
          update: false,
          delete: false,
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_msgContents('COMPONENTS.DELETE', { defaultValue: '삭제' }),
                elementAttr: { class: 'btn_XS white light_filled trash' },
                height: 30,
                onClick: () => this.onDeleteData(),
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_msgContents('COMMON.WORD.LOAD_HOLIDAY', { defaultValue: '휴일 불러오기' }),
                elementAttr: { class: 'btn_XS white light_filled' },
                height: 30,
                onClick: () => {
                  const holidayList = this.getHolidayList();
                  this.onOpenModal('holidaytp', holidayList);
                }
              },
              location: 'before',
            },
          ],
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: false,
          expandMode: 'rowClick', // rowClick or buttonClick
        },
        dragging: {
          sortColumn: 'date',
          allowReordering: false,
          dropFeedbackMode: 'push',
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple', // single multiple
        },
        // scrolling:{  // 미사용시 주석처리
        //     mode: 'virtual' //스크롤 모드 : ['infinite', 'standard', 'virtual']
        // },
        remoteOperations: {
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        paging: {
          // scrolling 미사용시만 적용됨
          enabled: false,
          pageSize: 10,
          pageIndex: 0, // 시작페이지
        },
        pager: {
          visible: false, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [], //페이지 사이즈 선택 박스
          displayMode: 'compact', //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: true,
        },
        headerFilter: {
          visible: true,
        },
        editing: {
          allowAdding: true,
          allowUpdating: true,
          allowDeleting: false,
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
        },
        selecting: {
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: 'ID',
            dataField: 'id',
            alignment: 'center', // left center right
            width: '150',
            visible: false,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'center', // left or right
          },
          {
            caption: '유형',
            i18n: 'COMMON.WORD.TYPE',
            dataField: 'holidayType',
            alignment: 'center', // left center right
            width: '150',
            visible: true,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'center', // left or right
            lookup: {
              dataSource: this.$_enums.common.holidayType.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
            validationRules: [
              {
                type: 'required',
                message: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.' }),
              },
            ],
            headerCellTemplate: container => {
              const div = document.createElement('div');
              div.innerHTML = `<span
                                  title="고정 휴일 : 매년 일자 변동이 없는 휴일
변동 휴일 : 음력, 임시 공휴일 등 매년 일자가 변경되는 휴일"
                                  class="mdi mdi-help-circle-outline"
                                >
                                </span>
                                &nbsp;
                                <span >유형</span>
                                `;
              container.append(div);
            },
          },
          {
            caption: '공휴일명',
            i18n: 'COMMON.WORD.HOLIDAY_NAME',
            dataField: 'holidayNm',
            alignment: 'center', // left center right
            visible: true,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'center', // left or right
            validationRules: [
              {
                type: 'required',
                message: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.' }),
              },
            ],
          },
          {
            caption: '공휴일',
            i18n: 'COMMON.WORD.HOLIDAY',
            dataField: 'holidayDt',
            alignment: 'center', // left center right
            visible: true,
            allowEditing: true,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            dataType: 'date',
            format: 'yyyy.MM.dd',
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'center', // left or right
            validationRules: [
              {
                type: 'required',
                message: this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.' }),
              },
            ],
            cellTemplate: (container, options) => {
              const dateValue = moment(options.value, 'YYYYMMDD');
              const condition = options.data.holidayType === this.$_enums.common.holidayType.FIXED.value;
              const formattedDate = condition ? dateValue.format('MM.DD') : dateValue.format('YYYY.MM.DD');
              container.append(formattedDate);
            },
          },
          {
            caption: '운영여부',
            i18n: 'COMMON.WORD.WORK_FLAG',
            dataField: 'workFl',
            width: '10%',
            alignment: 'center', // left center right
            visible: this.isCheckedHoliday,
            allowEditing: false,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'center', // left or right
            calculateCellValue: this.workFl,
            cellTemplate: (container, options) => {
              const switchBtn = new DxSwitch({
                propsData: {
                  value: options.value,
                  onValueChanged: e => {
                    const workFl = e.value
                      ? this.$_enums.cc.stringHolidayWorkFlag.YES.value
                      : this.$_enums.cc.stringHolidayWorkFlag.NO.value;
                    options.component.cellValue(options.rowIndex, 'workFl', workFl);
                  },
                },
              });
              switchBtn.$mount();
              container.append(switchBtn.$el);
            },
          },
        ],
      },
    };
	},
	computed: {
	},
	methods: {
    /** @description: 공휴일 리스트 가져오기 */
    getHolidayList() {
      return this.$refs[this.dataGrid.refName].getGridInstance.getVisibleRows()?.map(row => row.data)
        .map((holiday) => { //날짜 포맷
          holiday.holidayDt = moment(holiday.holidayDt).format('YYYYMMDD');
          return { ...holiday };
        });
    },
    /** @description: 공휴일 운영 visible 여부 */
    setWorkFl() {
      const holidayGrid = this.$refs[this.dataGrid.refName].getGridInstance;
      if( this.checkedDays.some(day => day === this.$_enums.common.integerDayOfWeek.HOLIDAY.value) ){
        this.isCheckedHoliday = true;
      } else {
        this.isCheckedHoliday = false;
      }
      holidayGrid.columnOption('workFl', { visible: this.isCheckedHoliday }); //운영여부 항목 visible 설정
    },
    /** @description: 팝업 열기
     * @param settingType 설정 타입(holidaytp)
     * @param settingData 모달로 넘길 설정 데이터 */
    onOpenModal(settingType, settingData) {
      this.modal[settingType].visible = true;
      this.modal[settingType].data = settingData;
    },
    /**
     * @description : 팝업 저장
     * @param settingType : 설정 타입(holidaytp)
     */
    async onSaveModal(settingType, holidaytpList) {
      holidaytpList.map(holiday => {
        holiday.holidayType = holiday.holidayTypeCd;
        holiday.holidayDt = holiday.holidayTm;
        holiday.id = null;

        return { ...holiday };
      });

      holidaytpList.forEach(row => {
        this.$refs[this.dataGrid.refName].getGridInstance.addRow();
      });

      holidaytpList.forEach((obj, index) => {
        Object.entries(obj).forEach(([key, value]) => {
          this.$refs[this.dataGrid.refName].getGridInstance.cellValue(index, key, value);
        });
      });

      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};

      this.$refs[this.dataGrid.refName].refreshData();
    },
    /** @description : 팝업 닫기
     * @param settingType : 설정 타입(notice, dnis)
     **/
    onCloseModal(settingType) {
      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};
    },
    /** @description: 그리드 행 추가시 초기 설정 */
    onInitNewRow(e) {
      e.data.holidayType = this.$_enums.common.holidayType.FIXED.value;
      e.data.holidayDt = getPastFromToday(0, 'days');
      if( this.isCheckedHoliday ) { //운영여부 설정
        e.data.workFl = false;
      }
    },
    /** @description: 그리드 저장 이벤트 */
    onSaving(e) {
      const holidayList = this.getHolidayList();
      this.$emit('saving', holidayList);
    },
		/** @description : 공휴일설정 휴일 추가 메서드 */
		onAddData() {
      this.$refs[this.dataGrid.refName].getGridInstance.addRow();
		},
		/** @description : 공휴일설정/특별운영시간설정 휴일 삭제 메서드 */
		async onDeleteData(key) {
      const selectedRowsData = this.$refs[this.dataGrid.refName].selectedRowsData;
      if (selectedRowsData.length === 0) {
        return this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
      }

      //삭제된 데이터 제외하여 다시 설정
      const listData = this.$refs[this.dataGrid.refName].getGridInstance.getVisibleRows()
        .map(d => d.data).filter(gridData => !selectedRowsData.map(rowData => rowData.id).includes(gridData.id));
      this.$refs[this.dataGrid.refName].getGridInstance.option('dataSource', listData);
		},
		/** @description: 공휴일설정 운영여부 출력 */
		workFl(rowData) {
			return rowData.workFl === this.$_enums.cc.stringHolidayWorkFlag.YES.value;
		},
	},
  mounted() {
    this.setWorkFl();  //공휴일 운영여부
  },
};
</script>
