<!--
  PACKAGE_NAME : src/pages/cc/ivr/schedule/config/modal-holidaytp.vue
  FILE_NAME : modal-holidaytp
  AUTHOR : hmlee
  DATE : 2024-09-25
  DESCRIPTION : 휴일 불러오기 모달
--> 
<template>
    <DxPopup
      :show-title="showTitle"
      :title="modal.title"
      :min-width="modal.minWidth"
      :width="modal.width"
      :min-height="modal.minHeight"
      :height="modal.height"
      :drag-enabled="modal.dragEnabled"
      :resize-enabled="modal.resizeEnabled"
      :show-close-button="modal.showCloseButton"
      :hide-on-outside-click="modal.hideOnOutsideClick"
      :visible="isOpen"
      @hiding="closeModal"
    >
    <template #content>
      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
    </template>
  
    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
        elementAttr: {
          class: 'default filled txt_S medium',
        },
        text: this.$_msgContents('COMPONENTS.SAVE', { defaultValue: '저장' }),
        width: '120',
        height: '40',
        useSubmitBehavior: true,
        onClick: this.saveModal,
      }"
    />
    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
        elementAttr: {
          class: 'white filled txt_S medium',
        },
        text: this.$_msgContents('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
        width: '120',
        height: '40',
        onClick: this.closeModal,
      }"
    />
  </DxPopup>
</template>

<script>
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxButton } from 'devextreme-vue/button';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  import CustomStore from "devextreme/data/custom_store";
  import { isSuccess, formatDate } from "@/plugins/common-lib";

  export default {
    components: {
      DxPopup,
      DxToolbarItem,
      DxButton,
      EspDxDataGrid,
    },
    props: {
      /** @description: 모달 오픈 여부 */
      isOpen: {
        default: false,
        type: Boolean,
      },
      /** @description: 휴일 리스트 */
      holidaytpList: {
        default: null,
        type: Array,
      },
    },
    watch: {
    },
    data() {
      return {
        modal: {
          title: this.$_msgContents('COMMON.WORD.LOAD_HOLIDAY', { defaultValue: '휴일 불러오기' }),
          minWidth: '300',
          width: '600',
          minHeight: '300',
          height: '500',
          dragEnabled: true,
          resizeEnabled: true,
          showCloseButton: true,
          hideOnOutsideClick: false,
        },
        dataGrid: {
          refName: 'holidaytpGrid',
          keyExpr: 'holidayId',
          allowColumnResizing: true, //컬럼 사이즈 허용
          allowReordering: true, //inline속성 느낌
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          hoverStateEnabled: true,
          dataSourceDefaultSortColumn: '+date',
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: '350', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {},
          showActionButtons: {
            // 그리드 버튼 노출 설정값
            select: false,
            update: false,
            delete: false,
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: false,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          dragging: {
            sortColumn: 'date',
            allowReordering: false,
            dropFeedbackMode: 'push',
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          // scrolling:{  // 미사용시 주석처리
          //     mode: 'virtual' //스크롤 모드 : ['infinite', 'standard', 'virtual']
          // },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '유형',
              i18n: 'COMMON.WORD.TYPE',
              dataField: 'holidayTypeCd',
              alignment: 'center', // left center right
              width: '20%',
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              lookup: {
                dataSource: this.$_enums.common.holidayType.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '공휴일명',
              i18n: 'COMMON.WORD.HOLIDAY_NAME',
              dataField: 'holidayNm',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
            },
            {
              caption: '공휴일',
              i18n: 'COMMON.WORD.HOLIDAY',
              dataField: 'mmdd',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              calculateDisplayCellValue: rowData => {
                let holidayDt;
                if (rowData.holidayTypeCd === this.$_enums.common.holidayType.FIXED.value) {
                  holidayDt = formatDate(rowData.date, 'MMDD', 'MM.DD');
                } else {
                  holidayDt = formatDate(rowData.holidayTm, 'YYYYMMDD', 'YYYY.MM.DD');
                }
                return holidayDt;
              },
            },
          ],
        },
      };
    },
    computed: {
      /** @description: 모달 타이틀 출력 여부 */
      showTitle() {
        return !!this.modal.title;
      },
    },
    methods: {
      /** @description: 모달 저장 */
      async saveModal() {
        const selectedRowsData = this.$refs[this.dataGrid.refName].selectedRowsData;
        if (selectedRowsData.length === 0) {
          return this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
        }

        this.$emit('saveModal', selectedRowsData);
      },
      /** @description: 모달 닫기 */
      closeModal() {
        this.$emit('closeModal');
      },
      /** @description: 데이터 조회 */
      async selectDataList() {
        const vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'holidayId',
          async load(loadOptions) {
            const params = vm.$_getDxDataGridParam(loadOptions);

            params.sort = vm.dataGrid.dataSourceDefaultSortColumn;

            //중복된 공휴일 날짜 제외
            const dateList = vm.holidaytpList?.map(day => day.holidayDt.slice(-4));
            if( dateList?.length > 0 ){
              params.date = `<>${dateList}`;
            }

            const payload = {
              actionname: 'HOLIDAY_LIST_ALL',
              data: { params },
              useErrorPopup: true,
            };

            let rtnData = {
              data: [],
              totalCount: 0,
            };
            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
            }
            return rtnData;
          },
        });
      },
    },
    created() {
      this.selectDataList();
    },
  };
</script>